import { Component, EventEmitter, Inject, OnInit, Output, NgModule } from '@angular/core';
import { EventModel } from '@app/events/events-edit-form/event.model'
//import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog }  from '@angular/material/legacy-dialog';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgForm, UntypedFormBuilder } from '@angular/forms';
import { DataArrayService } from '../events.service';
import {FormControl, Validators} from '@angular/forms';
import { EventsComponent } from '../events.component';
import { DataStorageService } from '@app/shared/services/data-storage.service';
import esJson from '../../../assets/i18n/es.json';
import enJson from '../../../assets/i18n/en.json';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { SnackBarService, ThemeService } from '@app/shared/services';



@Component({
    selector: 'app-events-edit-form',
    templateUrl: './events-edit-form.component.html',
    styleUrls: ['./events-edit-form.component.scss'],
    standalone: false
})
export class EventsEditFormComponent implements OnInit {

  langJson = (localStorage.getItem('lang') === 'es') ? esJson : enJson;
  minDate: Date;
  maxDate: Date;
  selectedTheme: any;
  selectedColor: any;
  uploadForm: any;
  imgURL: any;


  constructor(
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EventsEditFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data_form: any ,
    private dataArrayService: DataArrayService,
    public dataStorageService: DataStorageService,
    private formBuilder: UntypedFormBuilder,
    public themeService: ThemeService
    ) {
      const currentYear = new Date().getFullYear();
      const currentDate = new Date();
      this.minDate = new Date(currentDate);
      this.maxDate = new Date(currentYear + 20, 11, 31);
    }



  @Output() newItem = new EventEmitter<EventModel>();
  nuevoItem: EventModel = {
    id: '',
    name: '',
    startingDate: '',
    endingDate: '',
    startMoneyReturn: '',
    endMoneyReturn: '',
    returnMoneyCommission: '',
    selectedTheme: '',
    file: '',
    attendees: '',
    isTicketScan: '',
    hasNotifications: '',
    hasVouchers: '',
    hasAccessControl: '',
    isMoneyReturned: '',
    hasRegistration: '',
    hasExactPurchases: '',
    hasPurchases: '',
    hasTpvCash: '',
    hasTpvCard: '',
    hasTpvWristband: '',
    hasOnlyVouchers: '',
    hasQR: '',
    hasPreCharge: '',
  };

  ngOnInit(): void{
    this.nuevoItem.id = this.dataArrayService.getEventClicked().id;
    this.nuevoItem.name = this.dataArrayService.getEventClicked().name;
    this.nuevoItem.attendees = this.dataArrayService.getEventClicked().attendees;
    this.nuevoItem.startingDate = this.dataArrayService.getEventClicked().startingDate;
    //this.nuevoItem.startingDate = this.nuevoItem.startingDate.setHours(2, 0, 0);
    this.nuevoItem.endingDate = this.dataArrayService.getEventClicked().endingDate;
    this.nuevoItem.startMoneyReturn = this.dataArrayService.getEventClicked().startMoneyReturn;
    this.nuevoItem.endMoneyReturn = this.dataArrayService.getEventClicked().endMoneyReturn;
    this.nuevoItem.returnMoneyCommission = this.dataArrayService.getEventClicked().returnMoneyCommission;

    this.nuevoItem.selectedTheme = this.dataArrayService.getEventClicked().selectedTheme;
    this.nuevoItem.file = this.dataArrayService.getEventClicked().file;

    //this.nuevoItem.endingDate.setHours(3, 3, 3);
    this.nuevoItem.hasVouchers = this.dataArrayService.getEventClicked().hasVouchers;
    this.nuevoItem.hasAccessControl = this.dataArrayService.getEventClicked().hasAccessControl;

    this.nuevoItem.isMoneyReturned = this.dataArrayService.getEventClicked().isMoneyReturned;
    this.nuevoItem.hasRegistration = this.dataArrayService.getEventClicked().hasRegistration;
    this.nuevoItem.hasExactPurchases = this.dataArrayService.getEventClicked().hasExactPurchases;
    this.nuevoItem.hasPurchases = this.dataArrayService.getEventClicked().hasPurchases;
    this.nuevoItem.hasTpvCash = this.dataArrayService.getEventClicked().hasTpvCash;
    this.nuevoItem.hasTpvCard = this.dataArrayService.getEventClicked().hasTpvCard;
    this.nuevoItem.hasTpvWristband = this.dataArrayService.getEventClicked().hasTpvWristband;
    this.nuevoItem.hasOnlyVouchers = this.dataArrayService.getEventClicked().hasOnlyVouchers;
    this.nuevoItem.hasQR = this.dataArrayService.getEventClicked().hasQR;
    this.nuevoItem.hasPreCharge = this.dataArrayService.getEventClicked().hasPreCharge;

    const reader = new FileReader();
      reader.readAsDataURL(this.nuevoItem.file);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
    };

    this.uploadForm = this.formBuilder.group({
      profile: ['']
    });
  }

  agregarItem() {
    this.newItem.emit(this.nuevoItem);
    this.nuevoItem = {
      id: '',
      name: '',
      startingDate: '',
      endingDate: '',
      startMoneyReturn: '',
      endMoneyReturn: '',
      returnMoneyCommission: '',
      selectedTheme: '',
      file: '',
      attendees: '',
      isTicketScan: '',
      hasNotifications: '',
      hasVouchers: '',
      hasAccessControl: '',
      isMoneyReturned: '',
      hasRegistration: '',
      hasExactPurchases: '',
      hasPurchases: '',
      hasTpvCash: '',
      hasTpvCard: '',
      hasTpvWristband: '',
      hasOnlyVouchers: '',
      hasQR: '',
      hasPreCharge: '',
    };
  }


  nuevoDato: EventModel = {
    id: '',
    name: '',
    startingDate: '',
    endingDate: '',
    startMoneyReturn: '',
    endMoneyReturn: '',
    returnMoneyCommission: '',
    selectedTheme: '',
    file: '',
    attendees: '',
    isTicketScan: '',
    hasNotifications: '',
    hasVouchers: '',
    hasAccessControl: '',
    isMoneyReturned: '',
    hasRegistration: '',
    hasExactPurchases: '',
    hasPurchases: '',
    hasTpvCash: '',
    hasTpvCard: '',
    hasTpvWristband: '',
    hasOnlyVouchers: '',
    hasQR: '',
    hasPreCharge: '',
  };

  onSubmit(form: NgForm) {
    if (!form.valid){
      return;
    }
    this.dataArrayService.delete_event_state = "actualizar_evento";
    this.dataArrayService.setDeleteEventState(false);

    const id = this.nuevoItem.id;
    const nombre = form.value.nombre.trim();
    const attendees = form.value.attendees;
    const startingDate = (typeof form.value.startingDate === 'object') ? form.value.startingDate._d : form.value.startingDate;
    const endingDate = (typeof form.value.endingDate === 'object') ? form.value.endingDate._d : form.value.endingDate;
    const startMoneyReturn = (typeof form.value.startMoneyReturn === 'object') ? form.value.startMoneyReturn._d : form.value.startMoneyReturn;
    const endMoneyReturn = (typeof form.value.endMoneyReturn === 'object') ? form.value.endMoneyReturn._d : form.value.endMoneyReturn;
    const returnMoneyCommission = form.value.returnMoneyCommission;
    const selectedTheme = this.selectedTheme;
    const file = (this.imgURL !== undefined) ? this.uploadForm.get('profile').value : '';
    const isTicketScan = form.value.isTicketScan;
    const hasNotifications = form.value.hasNotifications;
    const hasVouchers = form.value.hasVouchers;
    const hasAccessControl = form.value.hasAccessControl;
    const isMoneyReturned = form.value.isMoneyReturned;
    const hasRegistration = form.value.hasRegistration;
    const hasExactPurchases = form.value.hasExactPurchases;
    const hasPurchases = form.value.hasPurchases;
    const hasTpvCash = form.value.hasTpvCash;
    const hasTpvCard = form.value.hasTpvCard;
    const hasTpvWristband = form.value.hasTpvWristband;
    const hasOnlyVouchers = form.value.hasOnlyVouchers;
    const hasQR = form.value.hasQR;
    const hasPreCharge = form.value.hasPreCharge;

    if (typeof startingDate === 'object'){
      startingDate.setHours(startingDate.getHours() + 3);
    }
    if (typeof endingDate === 'object'){
      endingDate.setHours(endingDate.getHours() + 3);
    }
    if (typeof startMoneyReturn === 'object'){
      startMoneyReturn.setHours(startMoneyReturn.getHours() + 3);
    }
    if (typeof endMoneyReturn === 'object'){
      endMoneyReturn.setHours(endMoneyReturn.getHours() + 3);
    }


    this.nuevoDato = {
      id: id,
      name: nombre,
      startingDate: startingDate,
      endingDate: endingDate,
      startMoneyReturn: startMoneyReturn,
      endMoneyReturn: endMoneyReturn,
      returnMoneyCommission: returnMoneyCommission,
      selectedTheme: selectedTheme,
      file: file,
      attendees: attendees,
      isTicketScan: isTicketScan,
      hasNotifications: hasNotifications,
      hasVouchers: hasVouchers,
      hasAccessControl: hasAccessControl,
      isMoneyReturned: isMoneyReturned,
      hasRegistration: hasRegistration,
      hasExactPurchases: hasExactPurchases,
      hasPurchases: hasPurchases,
      hasTpvCash: hasTpvCash,
      hasTpvCard: hasTpvCard,
      hasTpvWristband: hasTpvWristband,
      hasOnlyVouchers: hasOnlyVouchers,
      hasQR: hasQR,
      hasPreCharge: hasPreCharge,
    };

    let nuevoDatoFiltrado = {};
    for (let key in this.nuevoDato) {
      if (this.nuevoDato[key] !== "" && this.nuevoDato[key] !== undefined) {
        nuevoDatoFiltrado[key] = this.nuevoDato[key];
      }
    }

    this.dataStorageService.updateEventWithAccount(nuevoDatoFiltrado, this.nuevoItem.id);
    this.dataArrayService.addData(this.nuevoDato);
    this.dialogRef.close(this.nuevoDato);

  }


  onDelete(eventId: string){
      const dialogRef2 = this.dialog.open(ConfirmationDialogComponent, {
         width: '350px',
         data: 'Va a eliminarse el evento. ¿Está seguro?'
       });
      dialogRef2.afterClosed().subscribe(result => {
         if (result) {
          this.dataArrayService.setDeleteEventState(true);
          this.dataStorageService.deleteEventWithAccount(eventId)
          this.dialogRef.close(this.nuevoDato);
          this.snackBarService.openSnackBar('Evento eliminado correctamente.');
         }
       });
  }


  onClose(){
    this.dialogRef.close();
  }

  selectTheme(theme){
    this.selectedTheme = theme.theme;
    this.selectedColor = theme.primary;
    // this.f.value['theme'] = theme
  }

  putTheme(event) {
    this.selectedTheme = event.theme;
    this.selectedColor = event.primary;
  }

  preview(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.uploadForm.get('profile').setValue(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_event) => {
        this.imgURL = reader.result;
    };
    }
  }



  name_control = new FormControl('', [Validators.required]);

  getErrorMessage_name() {
    if (this.name_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

  attendees_control = new FormControl('', [Validators.required]);

  getErrorMessage_attendees() {
    if (this.attendees_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

  commision_control = new FormControl('', [Validators.required]);

  getErrorMessage_commision() {
    if (this.commision_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

  date_control = new FormControl('', [Validators.required]);

  getErrorMessage_init_date() {
    if (this.date_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }

  return_control = new FormControl('', [Validators.required]);

  getErrorMessage_return_date() {
    if (this.return_control.hasError('required')) {
      return this.langJson.events.formRequiredField;
    }
  }


}



